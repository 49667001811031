import { Component, computed, input, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { CountryDto, injectGenders } from '@nuis/common';
import { InputDateComponent, InputSingleSelectComponent, InputTextComponent, SelectOption } from '@nuis/forms';
import { EditPersonForm, PersonType } from '../edit-person-dialog/edit-person-dialog-form.type';

@Component({
    selector: 'nuis-edit-person-form',
    standalone: true,
    imports: [TranslatePipe, InputDateComponent, InputTextComponent, InputSingleSelectComponent],
    templateUrl: './edit-person-form.component.html',
})
export class EditPersonFormComponent {
    private readonly genders = injectGenders();

    public personType = input.required<PersonType>();
    public countries = input.required<CountryDto[]>();
    public form = input.required<FormGroup<EditPersonForm>>();

    protected genderOptions = signal<SelectOption<string>[]>(
        this.genders.map((type) => ({ value: type.key, label: type.name })),
    );
    protected countryOptions = computed<SelectOption<string>[]>(() =>
        (this.countries() ?? []).map((c) => ({ label: c.name, value: c.id })),
    );
}
