import { FormControl } from '@angular/forms';
import { Gender, PersonDto } from '@nuis/common';
import { DateTime } from 'luxon';

export interface EditPersonForm {
    gender: FormControl<Gender | null>;
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    nationalIdentificationNumber: FormControl<string | null>;
    dateOfBirth: FormControl<DateTime | null>;
    email: FormControl<string | null>;
    phoneNumber: FormControl<string | null>;

    addressLine1: FormControl<string | null>;
    addressLine2: FormControl<string | null>;
    postalCode: FormControl<string | null>;
    city: FormControl<string | null>;
    country: FormControl<string | null>;
}

export type EditPersonFormRawData = {
    [K in keyof EditPersonForm]: EditPersonForm[K] extends FormControl<infer U> ? U : never;
};

export type PersonType = 'InsuredPerson' | 'CallingPerson' | 'AdditionalInsuredPerson' | 'ContactPerson';

export function mapValuesToPerson(values: EditPersonFormRawData): PersonDto {
    return {
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: values.dateOfBirth,
        email: values.email,
        gender: values.gender,
        phoneNumber: values.phoneNumber,
        nationalIdentificationNumber: values.nationalIdentificationNumber,
        address: {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            postalCode: values.postalCode,
            country: values.country,
        },
    };
}

export function mapPersonToFormValues(person: PersonDto | null): EditPersonFormRawData {
    return {
        firstName: person?.firstName ?? null,
        lastName: person?.lastName ?? null,
        nationalIdentificationNumber: person?.nationalIdentificationNumber ?? null,
        dateOfBirth: person?.dateOfBirth ?? null,
        email: person?.email ?? null,
        phoneNumber: person?.phoneNumber ?? null,
        addressLine1: person?.address?.addressLine1 ?? null,
        addressLine2: person?.address?.addressLine2 ?? null,
        postalCode: person?.address?.postalCode ?? null,
        city: person?.address?.city ?? null,
        country: person?.address?.country ?? null,
        gender: person?.gender ?? null,
    };
}
