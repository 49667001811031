import { inject, Injectable, Provider, signal, Signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuditsApiService } from '../services/api';
import { AuditModel } from './audit.model';

export function provideAuditsState(): Provider[] {
    return [AuditsStateModel, { provide: AuditsState, useClass: AuditsStateModel }];
}

@Injectable()
export abstract class AuditsState {
    public abstract isLoading: Signal<boolean>;
    public abstract audits: Signal<AuditModel[]>;

    public abstract getAudits(options: { entityId: string; entityTypes: string[] }): Promise<void>;
}

@Injectable()
class AuditsStateModel extends AuditsState {
    private readonly auditsApiService = inject(AuditsApiService);

    public override isLoading = signal<boolean>(true);
    public override audits = signal<AuditModel[]>([]);

    public override async getAudits(options: { entityId: string; entityTypes: string[] }) {
        try {
            this.isLoading.set(true);
            this.audits.set([]);

            const audits = await firstValueFrom(
                this.auditsApiService.getAuditLogs(options.entityId, options.entityTypes),
            );

            this.audits.set(audits);
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading.set(false);
        }
    }
}
