import { FormGroup, NonNullableFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { getDateOfBirthFromJMBG, JMBGValidator, PersonDto } from '@nuis/common';
import { EditPersonForm, PersonType } from './edit-person-dialog-form.type';

export function initEditPersonForm(
    fb: NonNullableFormBuilder,
    person: Partial<PersonDto> | null,
    isSRB: boolean,
    personType: PersonType,
): FormGroup<EditPersonForm> {
    const form = fb.group<EditPersonForm>({
        gender: fb.control(person?.gender ?? null, [Validators.required]),
        firstName: fb.control(person?.firstName ?? null, [Validators.required]),
        lastName: fb.control(person?.lastName ?? null, [Validators.required]),
        nationalIdentificationNumber: fb.control(person?.nationalIdentificationNumber ?? null, ninValidator(isSRB)),
        dateOfBirth: fb.control(person?.dateOfBirth ?? null, [Validators.required]),
        email: fb.control(person?.email ?? null, emailValidator(personType)),
        phoneNumber: fb.control(person?.phoneNumber ?? null),

        addressLine1: fb.control(person?.address?.addressLine1 ?? null),
        addressLine2: fb.control(person?.address?.addressLine2 ?? null),
        postalCode: fb.control(person?.address?.postalCode ?? null),
        city: fb.control(person?.address?.city ?? null),
        country: fb.control(person?.address?.country ?? null),
    });

    subscribeToNationalIdentificationNumberChanges(form, isSRB);

    return form;
}

function emailValidator(personType: PersonType): ValidatorFn[] {
    return personType === 'InsuredPerson' ? [Validators.required, Validators.email] : [Validators.email];
}

function ninValidator(isSRB: boolean): ValidatorFn[] {
    return isSRB ? [JMBGValidator] : [];
}

// Calculate the birthdate from the JMBG
function subscribeToNationalIdentificationNumberChanges(form: FormGroup<EditPersonForm>, isSRB: boolean): void {
    form.controls.nationalIdentificationNumber.valueChanges.subscribe((value) => {
        if (form.controls.nationalIdentificationNumber.valid && isSRB) {
            form.controls.dateOfBirth.setValue(getDateOfBirthFromJMBG(value));
        }
    });
}
