<div class="p-3 surface-ground overflow-auto">
    <nuis-edit-person-form [form]="form" [countries]="countries()" [personType]="personType()" />
</div>

<div class="flex gap-3 justify-content-end p-3">
    <p-button
        [label]="'button.cancel' | translate"
        [text]="true"
        severity="secondary"
        [disabled]="isSaving()"
        (onClick)="cancel()" />

    <p-button [label]="'actions.save' | translate" [loading]="isSaving()" (onClick)="save()" />
</div>
