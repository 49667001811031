import { Component, inject, signal } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { Configuration, injectDialogOptions } from '@nuis/common';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditPersonFormComponent } from '../edit-person-form/edit-person-form.component';
import { EditPersonDialogOptions } from './edit-person-dialog-options.type';
import { initEditPersonForm } from './edit-person-dialog.form';

@Component({
    selector: 'nuis-edit-person-dialog',
    standalone: true,
    imports: [TranslatePipe, ButtonModule, EditPersonFormComponent],
    templateUrl: './edit-person-dialog.component.html',
})
export class EditPersonDialogComponent {
    private readonly options = injectDialogOptions<EditPersonDialogOptions>();
    private readonly fb = inject(NonNullableFormBuilder);
    private readonly dialogRef = inject(DynamicDialogRef);
    private readonly config = inject(Configuration);
    private readonly isSrb = this.config.partnerIds.a1_srb.toLowerCase() === this.options.partnerId.toLowerCase();

    protected personType = signal(this.options.personType);
    protected countries = signal(this.options.countries ?? []);

    protected form = initEditPersonForm(this.fb, this.options.person, this.isSrb, this.options.personType);

    protected isSaving = signal<boolean>(false);

    protected async save() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        try {
            this.isSaving.set(true);
            const values = this.form.getRawValue();
            await this.options.onSave(values);
            this.dialogRef.close(true);
        } catch (error) {
            console.error(error);
        } finally {
            this.isSaving.set(false);
        }
    }

    protected cancel() {
        this.dialogRef.close();
    }
}
