import { mediumDialogSettings, openDialog } from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { EditPersonDialogOptions } from './edit-person-dialog-options.type';
import { EditPersonDialogComponent } from './edit-person-dialog.component';

export async function openEditPersonDialog(
    dialogService: DialogService,
    options: EditPersonDialogOptions,
): Promise<boolean> {
    const result = await openDialog<EditPersonDialogComponent, boolean>(dialogService, EditPersonDialogComponent, {
        ...mediumDialogSettings,
        header: options.header,
        data: options,
        focusOnShow: false,
    });

    return result ?? false;
}
