<div class="grid">
    <nuis-input-single-select
        class="col-12 md:col-4"
        [label]="'salutation' | translate"
        [control]="form().controls.gender"
        [options]="genderOptions()" />

    <div class="md:col-8"></div>

    <nuis-input-text class="col-12 md:col-6" [label]="'firstname' | translate" [control]="form().controls.firstName" />

    <nuis-input-text class="col-12 md:col-6" [label]="'lastname' | translate" [control]="form().controls.lastName" />

    @if (personType() !== 'ContactPerson') {
        <nuis-input-date
            class="col-12 md:col-4"
            [label]="'birthdate' | translate"
            [control]="form().controls.dateOfBirth"
            [isUtc]="true" />

        <nuis-input-text
            class="col-12 md:col-8"
            [label]="'nationalIdentificationNumber' | translate"
            [control]="form().controls.nationalIdentificationNumber" />
    }

    @if (personType() === 'CallingPerson' || personType() === 'InsuredPerson' || personType() === 'ContactPerson') {
        <nuis-input-text class="col-12 md:col-6" [label]="'email' | translate" [control]="form().controls.email" />

        <nuis-input-text
            class="col-12 md:col-6"
            [label]="'phone' | translate"
            [control]="form().controls.phoneNumber" />
    }

    @if (personType() === 'InsuredPerson') {
        <div class="col-12 p-3"></div>

        <nuis-input-text
            class="col-12 md:col-8"
            [label]="'street' | translate"
            [control]="form().controls.addressLine1" />

        <nuis-input-text
            class="col-12 md:col-4"
            [label]="'additional' | translate"
            [control]="form().controls.addressLine2" />

        <nuis-input-text class="col-12 md:col-4" [label]="'zip' | translate" [control]="form().controls.postalCode" />
        <nuis-input-text class="col-12 md:col-8" [label]="'city' | translate" [control]="form().controls.city" />

        <nuis-input-single-select
            class="col-12 md:col-6"
            [label]="'country' | translate"
            [control]="form().controls.country"
            [options]="countryOptions()" />
    }
</div>
