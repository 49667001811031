<div class="flex flex-column gap-2">
    <nuis-card-header [title]="'notes.notes' | translate">
        <p-button
            [text]="true"
            icon="pi pi-plus"
            severity="success"
            [pTooltip]="'notes.createHeader' | translate"
            tooltipPosition="left"
            (onClick)="handleCreate()" />
    </nuis-card-header>

    @if (isLoading()) {
        <div class="flex justify-content-center">
            <nuis-loading-indicator />
        </div>
    } @else {
        @if (sortedNotes().length > 0) {
            <div class="flex flex-column gap-2">
                @for (note of sortedNotes(); track note.id) {
                    <nuis-card>
                        <div class="flex gap-3">
                            <nuis-profile-picture [email]="note.createdBy" />

                            <div class="flex-1 flex flex-column gap-1">
                                <div class="flex justify-content-between text-sm text-500">
                                    <div>{{ note.createdBy | partnerName }}</div>

                                    <div class="flex gap-1">
                                        <div>
                                            {{ note.createdOn | luxonDateTime }}
                                        </div>

                                        @if (note.modifiedOn !== null) {
                                            <div
                                                class="cursor-pointer"
                                                [pTooltip]="(note.modifiedOn | luxonDateTime) ?? ''">
                                                {{ 'notes.modifiedNote' | translate }}
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div class="flex-1 flex gap-3">
                                    <div class="flex-1 flex flex-column gap-1 break-words">
                                        <div class="font-bold">{{ note.subject }}</div>

                                        <div>{{ note.body }}</div>
                                    </div>

                                    <div class="flex gap-2 align-items-center">
                                        @if (isEditable()(note)) {
                                            <p-button
                                                [text]="true"
                                                icon="pi pi-pencil"
                                                [pTooltip]="'tooltip.edit' | translate"
                                                tooltipPosition="left"
                                                (onClick)="handleEdit(note)" />
                                        }

                                        @if (isRemoveable()(note)) {
                                            <p-button
                                                [text]="true"
                                                icon="pi pi-trash"
                                                severity="danger"
                                                [pTooltip]="'actions.delete' | translate"
                                                tooltipPosition="left"
                                                [loading]="isRemoving()(note)"
                                                (onClick)="handleRemove(note)" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nuis-card>
                }
            </div>
        } @else {
            <nuis-card>
                <div class="text-center text-500">
                    {{ 'noNotesFound' | translate }}
                </div>
            </nuis-card>
        }
    }
</div>
