import { inject, Injectable, Provider, Signal, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    DocumentApiService,
    DocumentDto,
    downloadFileFromBase64,
    FileUpload,
    openConfirmDialog,
    UserService,
} from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { openDocumentRenameDialog } from '../components/document-rename-dialog';

export function provideDocumentsState(): Provider[] {
    return [DocumentsStateModel, { provide: DocumentsState, useClass: DocumentsStateModel }];
}

@Injectable()
export abstract class DocumentsState {
    public abstract reference: Signal<{ refId: string; partnerId: string } | null>;
    public abstract isLoading: Signal<boolean>;
    public abstract documents: Signal<DocumentDto[]>;
    public abstract uploading: Signal<FileUpload<DocumentDto>[]>;

    public abstract init(options: { refId: string; partnerId: string; loadExisting?: false }): Promise<void>;
    public abstract upload(files: File[]): Promise<void>;
    public abstract isDownloading(document: DocumentDto): boolean;
    public abstract download(document: DocumentDto): Promise<void>;
    public abstract rename(event: DocumentDto): Promise<void>;
    public abstract isRemoving(document: DocumentDto): boolean;
    public abstract remove(document: DocumentDto): Promise<void>;
}

@Injectable()
class DocumentsStateModel extends DocumentsState {
    private readonly userService = inject(UserService);
    private readonly documentApiService = inject(DocumentApiService);
    private readonly dialogService = inject(DialogService);
    private readonly translate = inject(TranslateService);

    public override reference = signal<{ refId: string; partnerId: string } | null>(null);
    public override isLoading = signal<boolean>(true);
    public override documents = signal<DocumentDto[]>([]);
    public override uploading = signal<FileUpload<DocumentDto>[]>([]);

    protected downloadingNames = signal<string[]>([]);
    protected removingNames = signal<string[]>([]);

    public override async init(options: { refId: string; partnerId: string; loadExisting?: boolean }): Promise<void> {
        const refId = options.refId;
        const partnerId = options.partnerId;
        const loadExisting = options.loadExisting ?? true;

        this.reference.set({ refId, partnerId });

        try {
            this.isLoading.set(true);

            const documents = await (loadExisting
                ? firstValueFrom(this.documentApiService.getDocuments(refId, partnerId))
                : Promise.reject([]));
            this.documents.set(documents);
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading.set(false);
        }
    }

    public override async upload(files: File[]) {
        const reference = this.reference();
        if (reference === null) {
            return;
        }

        const user = this.userService.user();

        for (const file of files) {
            const upload = new FileUpload({
                file: file,
                handleUpload: ({ nameStrategy, file }) => {
                    return this.documentApiService.upload(
                        reference.refId,
                        reference.partnerId,
                        file,
                        user.email,
                        nameStrategy,
                    );
                },
                handleCancel: () => {
                    this.uploading.update((uploads) => uploads.filter((u) => u !== upload));
                },
                handleComplete: (dto) => {
                    this.uploading.update((uploads) => uploads.filter((u) => u !== upload));
                    this.documents.update((docs) => [...docs.filter((d) => d.fullName !== dto.fullName), dto]);
                },
            });

            this.uploading.update((uploads) => [...uploads, upload]);
            upload.start();
        }
    }

    public override isDownloading(d: DocumentDto): boolean {
        return this.downloadingNames().includes(d.fullName);
    }

    public override async download(document: DocumentDto): Promise<void> {
        try {
            this.downloadingNames.update((ids) => [...ids, document.fullName]);
            const content = await firstValueFrom(this.documentApiService.download(document));
            downloadFileFromBase64(content.contentType, content.content, content.name);
        } catch (error) {
            console.error(error);
        } finally {
            this.downloadingNames.update((ids) => ids.filter((id) => id !== document.fullName));
        }
    }

    public override async rename(document: DocumentDto): Promise<void> {
        await openDocumentRenameDialog(this.dialogService, this.translate, {
            document: document,
            doesFileExist: async (fileName: string) =>
                firstValueFrom(
                    this.documentApiService.checkIfDocumentsExists(document.refId, document.partnerId, fileName),
                ),
            onSave: async (newName: string) => {
                const result = await firstValueFrom(this.documentApiService.rename(document, newName));
                this.documents.update((docs) => docs.map((d) => (d === document ? result : d)));
            },
        });
    }

    public override isRemoving(d: DocumentDto): boolean {
        return this.removingNames().includes(d.fullName);
    }

    public override async remove(document: DocumentDto): Promise<void> {
        const reference = this.reference();
        if (reference === null) {
            return;
        }

        const accept = await openConfirmDialog(this.dialogService, {
            header: this.translate.instant('documents.confirmation.header'),
            message: this.translate.instant('documents.confirmation.message'),
            acceptLabel: this.translate.instant('actions.delete'),
            acceptSeverity: 'danger',
        });
        if (!accept) {
            return;
        }

        try {
            this.removingNames.update((ids) => [...ids, document.fullName]);
            await firstValueFrom(
                this.documentApiService.remove({
                    refId: document.refId,
                    partnerId: document.partnerId,
                    fileName: document.fullName,
                }),
            );
            this.documents.update((docs) => docs.filter((d) => d !== document));
        } catch (error) {
            console.error(error);
        } finally {
            this.removingNames.update((ids) => ids.filter((id) => id !== document.fullName));
        }
    }
}
