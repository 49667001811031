import { Component, computed, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DisplayTextComponent } from '@nuis/common';
import { injectBankNumberConfig } from '../../utils';

@Component({
    selector: 'nuis-display-bank-number',
    standalone: true,
    imports: [TranslatePipe, DisplayTextComponent],
    template: ` <nuis-display-text [label]="'bankAccountNumber' | translate" [value]="formattedValue()" /> `,
})
export class DisplayBankNumberComponent {
    public value = input.required<string | null>();
    public isSrb = input.required<boolean>();

    private readonly bankNumberConfig = injectBankNumberConfig(this.isSrb);

    protected formattedValue = computed(() => this.bankNumberConfig().formatter(this.value()));
}
